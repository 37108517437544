import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Image from '../../components/Image';
import Resource from '../../components/Resource';
import Subfooter from '../../components/Subfooter';
import Hero from '../../components/Hero';
import '../../styles/pages/_about.scss';
import imgGuidingPrinciples from '../../images/img-home-guiding-principles.png';

const AboutOurMissionPage = () => (
  <Layout title="Our Mission">
    <Hero
      className={'hero--small course__hero'}
      modal
      imageSource="img-about-lead.jpg"
      guidSrc="a5310375-ad4d-4d9b-ae87-c18dc8c8a289"
      posterSrc={'how-to-video-stills.gif'}
      color={'blue'}
      playtext={'How to Video'}
    >
      <h1 className="pb-1">About our Program</h1>
      <p>
        Bring biotechnology out of the laboratory and into the classroom with
        all-new high school curriculum and Professional Learning tools. These
        ready-to-use resources are aligned to industry and education standards
        and are designed to help every teacher and student embrace their own
        biotech community.
      </p>
    </Hero>
    <Section className="mt-0 pb-0 pt-0">
      {/* Intro and Mission */}
      <Container fullWidth className="pl-0 pr-0 container--complete-width">
        <Row className="mr-0">
          <Column size={6}>
            <Image
              className="our-mission__image-column"
              filename="img-about-our-mission.jpg"
            />
          </Column>
          <Column size={6} className="our-mission__mission-statement">
            <div>
              <h2 className="mb-0.5 text-bold">Our Mission</h2>
              <div className="h3 mt-0 text-bold">
                DISCOVER THE POSSIBILITIES OF BIOTECH FROM MOLECULE TO MEDICINE.
              </div>
              <p>
                Join us as we deepen student understanding of the full product
                development life cycle and maximize student exposure to biotech
                industry trends, practical applications of biotech skills and
                concepts, career paths, and industry professionals. Inspire the
                next generation of biotech professionals to build a healthier
                future.
              </p>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="our-mission__guiding-principles">
      {/* Guiding Principles */}
      <Container fullWidth className="pt-2 pb-2">
        <Row className="our-mission__guiding-principles-container">
          <Column size={8}>
            <img
              src={imgGuidingPrinciples}
              alt="Guiding Principles"
              className="our-mission__guiding-principles-image"
            />
          </Column>
          <Column size={4} className="our-mission__guiding-principles-content">
            <h3 className="text-bold">Our Guiding Principles</h3>
            <p>
              Immersing students in real science and authentic career learning
              helps nurture collaborative, creative, and compassionate
              classrooms. We are committed to giving every student a doorway to
              the exciting careers of tomorrow by exploring the ways
              biotechnology breakthroughs are changing the world around us.
            </p>
          </Column>
        </Row>
      </Container>
      <Container fullWidth>
        <Row verticalAlignCenter>
          <Column size={6}>
            <Image
              filename="icon-about-equity-lens.png"
              className="our-mission__guiding-principles-icon mb-1 mt-2"
            />
            <div className="our-mission__guiding-principles-heading h4 text-bold">
              EQUITY LENS TO ENGAGE ALL LEARNERS
            </div>
            <p className="our-mission__guiding-principles-body">
              Futurelab+ is designed to equip educators with strategies to
              engage students from diverse racial, ethnic, and cultural groups.
              Instructional materials are framed to engage students with
              quality, equitable, and liberating educational experiences that
              validates and affirms student identity.
            </p>
          </Column>
          <Column size={6}>
            <Image
              filename="icon-about-industry-engagment.png"
              className="our-mission__guiding-principles-icon mb-1 mt-2"
            />
            <div className="our-mission__guiding-principles-heading h4 text-bold">
              INDUSTRY ENGAGEMENT AND CAREER EXPLORATION
            </div>
            <p className="our-mission__guiding-principles-body">
              Our curriculum maximizes student and teacher exposure to the
              cutting-edge world of biotech. Introduce students to industry
              professionals, explore industry trends, and practice applying
              skills and concepts via coaching support from real-world industry
              professionals.
            </p>
          </Column>
        </Row>
        <Row>
          <Column size={6}>
            <Image
              filename="icon-about-community.png"
              className="our-mission__guiding-principles-icon mb-1 mt-2"
            />
            <div className="our-mission__guiding-principles-heading h4 text-bold">
              PROFESSIONAL LEARNING TOOLS FOR SCIENCE EDUCATORS
            </div>
            <p className="our-mission__guiding-principles-body">
              With Professional Learning tools designed by teachers for
              teachers, we aim to equip educators with everything they need to
              become biotech ambassadors in school and help make the
              biotechnology pathway accessible to all students.
            </p>
          </Column>
          <Column size={6}>
            <Image
              filename="icon-about-industry.png"
              className="our-mission__guiding-principles-icon mb-1 mt-2"
            />
            <div className="our-mission__guiding-principles-heading h4 text-bold">
              INDUSTRY AND EDUCATION STANDARDS ALIGNMENT
            </div>
            <p className="our-mission__guiding-principles-body">
              Our resources are all designed to meet education and industry
              standards, focusing on in-demand skills needed across the full
              product development life cycle. We are dedicated to the goal of
              allowing students who complete this curriculum to earn dual
              enrollment credit from a partnering community college.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-0">
      {/* Program Offerings */}
      <Container fullWidth className="program-offering">
        <Row>
          <Column size={9}>
            <h3 className="mb-0.5 text-bold program-offering__heading">
              Program Offerings
            </h3>
            <div className="h4 mb-0 mt-0 text-all-caps text-bold program-offering__sub-head">
              High School Curriculum AND Professional Learning
            </div>
            <p>
              Dive into interactive digital learning resources that inspire
              students and teachers to investigate problems locally and globally
              using relevant technical skills in biotech.
            </p>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <Resource
              withBorder={false}
              asCard
              img="img-home-college-career.jpg"
              title="College and Career Readiness"
              description="Connect students to the amazing world of biotech and the changing face of the STEM workforce with industry-aligned career explorations."
              actions={[
                {
                  label: 'Explore Careers',
                  type: 'link',
                  path: '/college-career-readiness/career-finder/',
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              withBorder={false}
              asCard
              img="img-home-classroom-resources.jpg"
              title="Biotech in the Classroom"
              description="Take biotech beyond the lab bench with thought-provoking resources that contextualize biotechnology within the framework of everyday life."
              actions={[
                {
                  type: 'link',
                  path: '/classroom-instruction/living-earth/',
                  label: 'Explore Lessons',
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              withBorder={false}
              asCard
              img="img-home-educators-action.jpg"
              title="Professional Learning"
              description="Incorporate the innovative world of biotech into STEM lessons of every kind with the help of timely Professional Learning tools."
              actions={[
                {
                  label: 'Get Started',
                  type: 'link',
                  path: '/educators-in-action/',
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-0 pt-0">
      <Subfooter content="Inspiring lifelong learners who can solve the most pressing social challenges using bold breakthroughs in biotechnology and beyond." />
    </Section>
  </Layout>
);

export default AboutOurMissionPage;
